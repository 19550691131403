import { Container } from '@/atoms';
import { BackgroundVideoText as VideoText } from '@/organisms';

import { backgroundVideoTextTransformer } from '@/lib/graphql/transformers';

/**
 * BackgroundVideoText
 */
export const BackgroundVideoText = ({ bloque, id, className }: any) => (
  <Container
    id={id}
    padding="md:py-8 py-4"
    as="section"
    {...(className && { className })}
  >
    <VideoText {...backgroundVideoTextTransformer(bloque)} />
  </Container>
);

BackgroundVideoText.displayName = 'BackgroundVideoText';
