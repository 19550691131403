import { createRef, useEffect } from 'react';

import { debug } from '@/lib/utils';

import type TypeHls from 'hls.js';
import type { FunctionComponent } from 'react';
import type { TypeVideoM3u8Props } from './types';

/**
 * VideoM3u8
 */
export const VideoM3u8: FunctionComponent<TypeVideoM3u8Props> = ({
  hlsConfig,
  playerRef = createRef<HTMLVideoElement>(),
  getHLSRef,
  src,
  autoPlay,
  ...props
}: TypeVideoM3u8Props) => {
  useEffect(() => {
    (async () => {
      if (!playerRef.current?.canPlayType('application/vnd.apple.mpegurl')) {
        const Hls = (await import('hls.js')).default;
        let hls: TypeHls;

        function _initPlayer() {
          if (hls) hls.destroy();

          const newHls = new Hls({
            enableWorker: false,
            ...hlsConfig,
          });

          if (getHLSRef) getHLSRef(newHls);

          if (playerRef.current !== null) newHls.attachMedia(playerRef.current);

          newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
            newHls.loadSource(src);

            newHls.on(Hls.Events.MANIFEST_PARSED, () => {
              if (autoPlay) {
                playerRef?.current
                  ?.play()
                  .catch(() =>
                    debug(
                      'Unable to autoplay prior to user interaction with the dom.',
                    ),
                  );
              }
            });
          });

          newHls.on(Hls.Events.ERROR, function (_, data) {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  newHls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  newHls.recoverMediaError();
                  break;
                default:
                  _initPlayer();
                  break;
              }
            }
          });

          hls = newHls;
        }

        // Check for Media Source support
        if (Hls.isSupported()) {
          const video = playerRef.current;

          if (video) {
            for (const [key, value] of Object.entries(props)) {
              video.setAttribute(key, value as string);
            }
          }

          _initPlayer();
        }

        return () => {
          if (hls) hls.destroy();
        };
      }
    })();
  }, [autoPlay, hlsConfig, playerRef, getHLSRef, src]);

  // Fallback to using a regular video player if HLS is supported by default in the user's browser
  return <video ref={playerRef} src={src} autoPlay={autoPlay} {...props} />;
};

VideoM3u8.displayName = 'VideoM3u8';
