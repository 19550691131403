import { removeBaseURL } from '@/lib/utils';

import type { BackgroundVideoTextGraphQL } from '@/lib/graphql/types';
import type { TypeBackgroundVideoTextProps } from '@/organisms/background-video-text';

export const backgroundVideoTextTransformer = (
  bloque: BackgroundVideoTextGraphQL,
): TypeBackgroundVideoTextProps => ({
  video:
    bloque.typeVideo === 'youtube'
      ? {
          type: 'youtube',
          videoId: bloque.videoId,
        }
      : bloque.typeVideo === 'wordpress'
        ? {
            type: 'wordpress',
            sources: bloque.videoWordpress.map((video) => ({
              src: video.video.mediaItemUrl,
              type: video.mimeType,
            })),
          }
        : {
            type: 'm3u8',
            src: bloque.m3u8.mediaItemUrl,
          },
  ...(bloque?.title && {
    title: bloque.title,
  }),
  ...(bloque?.description && {
    description: bloque.description,
  }),
  ...(bloque?.label &&
    bloque?.link?.url && {
      button: {
        label: bloque.label,
        link: {
          url: removeBaseURL(bloque.link.url),
        },
        negativo: bloque.negativo,
        variant: bloque.variant,
        size: bloque.size,
      },
    }),
  desktopHeight: bloque.desktopHeight,
  mobileHeight: bloque.mobileHeight,
  titleColor: bloque.titleColor,
  descriptionColor: bloque.descriptionColor,
  ...(bloque?.align && {
    verticalAlignContent: bloque.align.reduce(
      (previousValue, currentValue) =>
        `${previousValue} ${
          currentValue.mediaQuery === 'none'
            ? currentValue.align
            : `${currentValue.mediaQuery}:${currentValue.align}`
        }`,
      '',
    ),
  }),
  ...(bloque?.justify && {
    horizontalAlignContent: bloque.justify.reduce(
      (previousValue, currentValue) =>
        `${previousValue} ${
          currentValue.mediaQuery === 'none'
            ? currentValue.justify
            : `${currentValue.mediaQuery}:${currentValue.justify}`
        }`,
      '',
    ),
  }),
  ...(bloque?.textAlign && {
    textAlign: bloque.textAlign.reduce(
      (previousValue, currentValue) =>
        `${previousValue} ${
          currentValue.mediaQuery === 'none'
            ? currentValue.textAlign
            : `${currentValue.mediaQuery}:${currentValue.textAlign}`
        }`,
      '',
    ),
  }),
  contentWidth: bloque.contentWidth,
});
