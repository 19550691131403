import dynamic from 'next/dynamic';

import { NextLink, Video } from '@/atoms';
import VideoM3u8 from '@/atoms/video-m3u8';
import { Button } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeBackgroundVideoTextProps } from './types';

const YouTube = dynamic(() => import('@/molecules/youtube-video'), {
  ssr: true,
});

/**
 * BackgroundVideoText
 */
export const BackgroundVideoText: FunctionComponent<
  TypeBackgroundVideoTextProps
> = ({
  video,
  title,
  description,
  button,
  desktopHeight,
  mobileHeight,
  descriptionColor,
  titleColor,
  horizontalAlignContent,
  verticalAlignContent,
  textAlign,
  contentWidth,
  autoplay = true,
  className = '',
}: TypeBackgroundVideoTextProps) => (
  <div className={`divBG relative overflow-hidden ${className}`}>
    <style>{`
      .divBG {
        height: ${mobileHeight}px;
      }

      @media (min-width: 768px) {
        .divBG {
          height: ${desktopHeight}px;
        }
      }
    `}</style>

    {video.type === 'youtube' && (
      <div className="absolute -left-full -top-[50px] right-0 h-[130%] w-[300%] max-sm:max-w-none sm:left-0 sm:top-1/2 sm:h-auto sm:w-full sm:-translate-y-1/2">
        <YouTube
          {...(autoplay && {
            onReady: (e) => e.target.playVideo(),
          })}
          videoId={video.videoId}
          opts={{
            height: '450px',
            width: '1000px',
            playerVars: {
              showinfo: 0,
              modestbranding: 1,
              rel: 0,
              autoplay: autoplay ? 1 : 0,
              controls: 0,
              loop: 1,
              mute: 1,
              iv_load_policy: 3,
              playsinline: 1,
              enablejsapi: 1,
              playlist: video.videoId,
            },
          }}
        />
      </div>
    )}

    {video.type === 'wordpress' && (
      <Video
        sources={video.sources}
        autoplay={autoplay}
        className="absolute -left-full -top-[50px] right-0 h-[130%] w-[300%] max-sm:max-w-none sm:left-0 sm:top-1/2 sm:h-auto sm:w-full sm:-translate-y-1/2"
      />
    )}

    {video.type === 'm3u8' && (
      <VideoM3u8
        src={video.src}
        preload="none"
        autoPlay={autoplay}
        loop
        muted
        playsInline
        disablePictureInPicture
        className="absolute -left-full -top-[50px] right-0 h-[130%] w-[300%] max-sm:max-w-none sm:left-0 sm:top-1/2 sm:h-auto sm:w-full sm:-translate-y-1/2"
      />
    )}

    {title || description || button ? (
      <div
        className={`absolute top-0 flex h-full w-full grow overflow-hidden p-4 md:p-8 ${horizontalAlignContent}`}
      >
        <div className={`flex ${verticalAlignContent}`}>
          <div
            className={`flex flex-col gap-4 ${textAlign}`}
            style={{
              maxWidth: `${contentWidth}px`,
            }}
          >
            {title && (
              <p
                className="u-headline text-[36px] leading-none md:text-[50px]"
                style={{
                  color: titleColor,
                }}
              >
                {title}
              </p>
            )}
            {description && (
              <div
                className="u-body u-body--s md:u-body--l"
                style={{
                  color: descriptionColor,
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}

            {button && button.label && button.link.url && (
              <NextLink href={button.link.url} passHref>
                <Button {...button} as="a">
                  {button.label}
                </Button>
              </NextLink>
            )}
          </div>
        </div>
      </div>
    ) : null}
  </div>
);

BackgroundVideoText.displayName = 'BackgroundVideoText';
